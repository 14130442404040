import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import dr from "assets/img/faces/dr.jpg";
import dra from "assets/img/faces/dra.jpg";
import lic from "assets/img/faces/lic.jpg";
import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function SectionTypography() {
  const classes = useStyles();
  return (
    
      <div className={classes.container}>
       
        <div id="images">
          <div className={classes.title}>
            <h2 style={{textAlign: "center" }} >Nuestro Equipo</h2>
          </div>
          <br />
          <GridContainer >
            <GridItem xs={6} sm={2} className={classes.marginLeft}>
              <h6 style={{textAlign: "center" }}>Dr. Oscar Calleros Zubiate</h6>
              <p style={{textAlign: "center" }}>Psiquiatra</p>
              <img
                src={dr}
                alt="..."
                className={classes.imgRoundedCircle + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem xs={6} sm={2} className={classes.marginLeft}>
              <h6 style={{textAlign: "center" }}>Dr. Juan A Cárdenas Medina</h6>
              <p style={{textAlign: "center" }}>Psiquiatra</p>
              <img
                src={dr}
                alt="..."
                className={classes.imgRoundedCircle + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem xs={6} sm={2} className={classes.marginLeft}>
              <h6 style={{textAlign: "center" }}>Dra. Abigail Castro Contreras</h6>
              <p style={{textAlign: "center" }}>Psiquiatra</p>
              <img
                src={dra}
                alt="..."
                className={classes.imgRoundedCircle + " " + classes.imgFluid}
              />
            </GridItem>
           <GridItem xs={6} sm={2} className={classes.marginLeft}>
              <h6 style={{textAlign: "center" }}>Dr. Heydi Rubí Sosa Díaz</h6>
              <p style={{textAlign: "center" }}>Psiquiatra</p>
              <img
                src={dra}
                alt="..."
                className={classes.imgRoundedCircle + " " + classes.imgFluid}
              />
            </GridItem>
            
            <GridItem xs={6} sm={2} className={classes.marginLeft}>
              <h6 style={{textAlign: "center" }}>Dr. Marco R. Aguilar Ortiz</h6>
              <p style={{textAlign: "center" }}>Pediatra</p>
              <img
                src={dr}
                alt="..."
                className={classes.imgRoundedCircle + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem xs={6} sm={2} className={classes.marginLeft}>
              <h6 style={{textAlign: "center" }}>Dra. Yolanda L. Elías Cuadros</h6>
              <p style={{textAlign: "center" }}>Neurofisióloga</p>
              <img
                src={dra}
                alt="..."
                className={classes.imgRoundedCircle + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem xs={6} sm={2} className={classes.marginLeft}>
              <h6 style={{textAlign: "center" }}>Mtra. Brenda A. Ortiz Cuéllar</h6>
              <p style={{textAlign: "center" }}>Psicóloga</p>
              <img
                src={lic}
                alt="..."
                className={classes.imgRoundedCircle + " " + classes.imgFluid}
              />
            </GridItem>
           <GridItem xs={6} sm={2} className={classes.marginLeft}>
              <h6 style={{textAlign: "center" }}>Lic. Gabriela Cerón Domínguez</h6>
              <p style={{textAlign: "center" }}>Psicóloga</p>
              <img
                src={lic}
                alt="..."
                className={classes.imgRoundedCircle + " " + classes.imgFluid}
              />
            </GridItem>
           <GridItem xs={6} sm={2} className={classes.marginLeft}>
              <h6 style={{textAlign: "center" }}>LTCH. Beatríz Santillán</h6>
              <p style={{textAlign: "center" }}>Comunicación y lenguaje</p>
              <img
                src={lic}
                alt="..."
                className={classes.imgRoundedCircle + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem xs={6} sm={2} className={classes.marginLeft}>
            <h6 style={{textAlign: "center" }}> Lic. Miriam Morales</h6>
              <p style={{textAlign: "center" }}> Psicóloga Pedagoga</p>
              <img
                src={lic}
                alt="..."
                className={classes.imgRoundedCircle + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem xs={6} sm={2} className={classes.marginLeft}>
              <h6 style={{textAlign: "center" }}>Lic. Brenda Loustaunau</h6>
              <p style={{textAlign: "center" }}>Psicóloga</p>
              <img
                src={lic}
                alt="..."
                className={classes.imgRoundedCircle + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem xs={6} sm={2} className={classes.marginLeft}>
              <h6 style={{textAlign: "center" }}></h6>
              <p style={{textAlign: "center" }}></p>
            </GridItem>
          </GridContainer>
          <GridContainer />
        </div>
        
      </div>
    
  );
}
