import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/completedStyle.js";

const useStyles = makeStyles(styles);

export default function SectionCompletedExamples() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2>¿QUIENES SOMOS?</h2>
            <h4>
            La Clínica Neurocom está formada por un equipo de profesionales en el área de la salud, que se ha planteado como objetivo ayudar y mejorar 
            la salud de cada persona de una forma integral.</h4>
            <h4>
            Nos caracterizamos por ofrecer un servicio integral contando con un protocolo de atención, manteniendo una comunicación constante y 
            complementaria entre nuestros especialistas para beneficio de nuestros pacientes.
            </h4>
            <h2>Misión</h2>
            <h4>Brindar un servicio de excelencia, con calidez y profesionalismo mediante un equipo multidiciplinario para dar al paciente una atención integral.</h4>
            <h4>Consolidarnos como una institución que brinde atención a la salud en todos sus rubros, así como reconocimiento por la excelencia en el servicio, 
            calidez y profesionalismo dentro de las áreas educativas, empresariales y de la salud.</h4>
            <h2>Valores</h2>
            
              <li>Actitud en el servicio</li>
              <li>Respeto</li>
              <li>Compromiso</li>
              <li>Honestidad</li>
              <li>Ética profesional</li>
              <li>Confianza</li>
              <li>Responsabilidad</li>
           
            

          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
