import React from "react";
// react plugin for creating date-time-picker
import Datetime from "react-datetime";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import LocalHospital from "@material-ui/icons/LocalHospital";
import SportsKabaddi from "@material-ui/icons/SportsKabaddi";
import Hearing from "@material-ui/icons/Hearing";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import GroupWork from "@material-ui/icons/GroupWork";
import School from "@material-ui/icons/School";
import ChildCare from "@material-ui/icons/ChildCare";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function SectionJavascript() {
  const classes = useStyles();

  const [classicModal1, setClassicModal1] = React.useState(false);
  const [classicModal2, setClassicModal2] = React.useState(false);
  const [classicModal3, setClassicModal3] = React.useState(false);
  const [classicModal4, setClassicModal4] = React.useState(false);
  const [classicModal5, setClassicModal5] = React.useState(false);
  const [classicModal6, setClassicModal6] = React.useState(false);
  const [classicModal7, setClassicModal7] = React.useState(false);
  

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>NUESTROS SERVICIOS</h2>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={6} sm={3} >
                <Button
                  color="primary"
                  block
                  onClick={() => setClassicModal1(true)}
                >
                  <LocalHospital className={classes.icon} />
                  Psiquiatría
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal,
                  }}
                  open={classicModal1}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal1(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <IconButton
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={() => setClassicModal1(false)}
                    >
                      <Close className={classes.modalClose} />
                    </IconButton>
                    <h4 className={classes.modalTitle}>Psiquiatría</h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>Este servicio tiene como objetivo brindar la atención médico psiquiátrica oportuna a los pacientes mediante una entrevista 
                      clínica, para realizar el diagnóstico y tratamiento adecuado para el paciente, canalizándolo al servicio que mejor convenga de 
                      acuerdo a las condiciones requeridas para el caso.</p>
                      <p>En el servicio de psiquiatría se brinda la atención de primera vez y subsecuente a los pacientes a través de distintas técnicas 
                      psicoterapéuticas y/o en combinación con tratamiento farmacológico.</p>
                      <p>En la Clínica Especializada Neurocom contamos con el servicio en Psiquiatría Infantil y de la Adolescencia (Paidopsiquiatra) 
                      quien es un médico que se especializa en el conocimiento del desarrollo psicobiológico del individuo desde su nacimiento hasta 
                      el inicio de la etapa adulta, así como en la prevención, diagnóstico, tratamiento y rehabilitación de los trastornos mentales y 
                      del comportamiento que afectan a los menores de edad.</p>
                      <p>Contamos con el servicio de Psiquiatría de Enlace que se encarga de la interfase entre psiquiatría y medicina. Tiene como 
                      objetivo el estudio y el tratamiento de las enfermedades médicas que padecen los pacientes con trastornos psiquiátricos crónicos, 
                      y las manifestaciones psiquiátricas de las enfermedades médicas, en todos los niveles de atención.
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    
                    <Button
                      onClick={() => setClassicModal1(false)}
                      color="danger"
                      simple
                    >
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
                
              </GridItem>
              <GridItem xs={6} sm={3} >
                <Button
                  color="primary"
                  block
                  onClick={() => setClassicModal2(true)}
                >
                  <SportsKabaddi className={classes.icon} />
                  Psiquiatría Geriátrica
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal,
                  }}
                  open={classicModal2}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal2(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <IconButton
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={() => setClassicModal2(false)}
                    >
                      <Close className={classes.modalClose} />
                    </IconButton>
                    <h4 className={classes.modalTitle}>Psiquiatría Geriátrica</h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                    El psiquiatra geriátrico es un médico especializado en el diagnóstico y tratamiento de desórdenes mentales que pueden aparecer en personas 
                    mayores. Estos desórdenes incluyen (pero no están limitados a) demencia, depresión, ansiedad y esquizofrenia tardía.</p>
                    <p>Personas mayores con una variedad de problemas visitan al psiquiatra geriátrico. Estos problemas incluyen dificultades para realizar cambios, 
                    estrés, muerte de seres queridos, depresión, problemas de memoria, historia familiar de demencia, ansiedad, agitación asociada con demencia o 
                    dificultades con el sueño. Algunas veces surgen problemas emocionales por primera vez en personas mayores que sufren de dolor crónico, mal de 
                    Parkinson, enfermedades cardíacas, diabetes, derrames cerebrales, u otros desórdenes médicos. El psiquiatra geriátrico ofrece una ayuda valiosa
                    a las personas mayores que están enfrentando cambios en su salud y funcionamiento.</p>
                    <p>Como el psiquiatra geriátrico también entiende el papel que desempeña la familia con respecto al cuidado del paciente, la educa acerca de la 
                    naturaleza de la enfermedad y como pueden enfrentarla mejor. Esto puede incluir derivar al paciente a otros servicios adecuados.
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    
                    <Button
                      onClick={() => setClassicModal2(false)}
                      color="danger"
                      simple
                    >
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
                
              </GridItem>
              <GridItem xs={6} sm={3} >
                <Button
                  color="primary"
                  block
                  onClick={() => setClassicModal3(true)}
                >
                  <Hearing className={classes.icon} />
                  Psicoterapia
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal,
                  }}
                  open={classicModal3}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal3(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <IconButton
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={() => setClassicModal3(false)}
                    >
                      <Close className={classes.modalClose} />
                    </IconButton>
                    <h4 className={classes.modalTitle}>Psicoterapia</h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                    Los psicólogos ayudan a las personas a llevar adelante vidas más felices, saludables y productivas. Aplican procedimientos científicamente 
                    válidos para la creación de hábitos más sanos y efectivos. </p>
                    <p>En la psicoterapia se utilizan diferentes enfoques. Uno de ellos es la terapia cognitiva conductual el cual es un modelo de tratamiento 
                    psicoeducativo, a corto plazo, activo, directivo, estructurado y dinámico que plantea cómo los diversos factores en los que se desenvuelve 
                    el ser humano interactúan para dar paso a un estilo de vida.</p>
                    <p>Para corregir las conductas desadaptativas y distorsiones cognitivas, la terapia cognitivo conductual utiliza diferentes métodos de 
                    modificación conductual así como procedimientos verbales. Así, se ayuda al paciente a la identificación y corrección de dichas conductas. </p>
                    <p>Con diferentes ejercicios y evaluaciones de las situaciones, el paciente va aprendiendo a corregirse a sí mismo, dándole así las herramientas 
                    para enfrentar las situaciones del día a día. </p>
                    <p>Con los niños se trabaja mediante actividades dinámicas tales como juegos, cuentos, dibujos, manejo de diferentes materiales, role-playing, 
                    entre otros. Es importante también el manejo con los padres y personas al cuidado del niño para modificaciones en el manejo con el niño que 
                    puedan influir en las conductas desadaptativas.</p>
                    <p>Temas comunes que se manejan en terapia cognitivo conductual con niños: excesos conductuales (hiperactividad, agresividad, habla mucho, 
                    berrinches), deficiencias conductuales (tímido, falta de atención, falta de motivación), problemas conductuales en casa y/o en el aula, 
                    problemas de atención, divorcio de los padres, duelo, enuresis, abuso sexual, deficiencias en habilidades sociales, establecimiento de 
                    límites, estilos de crianza, entre otros.
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    
                    <Button
                      onClick={() => setClassicModal3(false)}
                      color="danger"
                      simple
                    >
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
                
              </GridItem>
              <GridItem xs={6} sm={3}>
                <Button
                  color="primary"
                  block
                  onClick={() => setClassicModal4(true)}
                >
                  <AssignmentTurnedIn className={classes.icon} />
                  Psicodiagnóstico
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal,
                  }}
                  open={classicModal4}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <IconButton
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={() => setClassicModal4(false)}
                    >
                      <Close className={classes.modalClose} />
                    </IconButton>
                    <h4 className={classes.modalTitle}>Psicodiagnóstico</h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                    Para la evaluación psicológica o el psicodiagnóstico se aplica una serie de tests, llamada batería psicológica la cual está constituida por:
                    La evaluación psicológica permite detectar problemas en las diferentes esferas del desarrollo infanto-juvenil y en las etapas de adultez.
                    Pruebas psicométricas validadas en el contexto mexicano, dentro de esta categoría se encuentran las pruebas de inteligencia o coeficiente 
                    intelectual (CI), de personalidad, de aprendizaje y de intereses vocacionales por mencionar algunos.</p>
                    <ul>
                    <li>Pruebas proyectivas que permiten conocer la estructura psicodinámica del paciente.</li>
                    <li>Pruebas cualitativas como cuestionarios que permiten conocer la percepción de los diferentes agentes que rodean el contexto del paciente 
                    tales como el escolar, familiar y social.</li>
                    <li>La evaluación psicológica va dirigida a detectar problemas en el desarrollo infantil y juvenil como: discapacidad intelectual, trastorno 
                    de la comunicación y lenguaje, trastorno del espectro autista (TEA), trastorno por déficit de atención (TDA-H), trastorno del aprendizaje, 
                    trastornos emocionales (depresión, ansiedad, enuresis y encopresis), problemas de conducta, adicciones, orientación vocacional.</li>
                    <li>La evaluación en personas adultas se enfoca en detectar disfunciones generadas por: trastornos depresivo, trastornos de ansiedad, trastornos 
                    por consumo de alcohol y trastornos de la personalidad.</li>
                    <li>De acuerdo a las necesidades del paciente se discernirán las pruebas a aplicar. Una vez que se termina de evaluar y analizar cada una de 
                    las esferas, se entrega el psicodiagnóstico por escrito con las recomendaciones pertinentes para la derivación al tratamiento terapéutico 
                    y/o médico que con base en los resultados se elaborará un programa de intervención.</li>
                    </ul>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    
                    <Button
                      onClick={() => setClassicModal4(false)}
                      color="danger"
                      simple
                    >
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
                
              </GridItem>
              <GridItem xs={6} sm={3}>
                <Button
                  color="primary"
                  block
                  onClick={() => setClassicModal5(true)}
                >
                  <ChildCare className={classes.icon} />
                  Pediatría
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal,
                  }}
                  open={classicModal5}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <IconButton
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={() => setClassicModal5(false)}
                    >
                      <Close className={classes.modalClose} />
                    </IconButton>
                    <h4 className={classes.modalTitle}>Pediatría</h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>Pediatría es la rama de la medicina que se especializa de forma integral en la salud y enfermedades en el periodo de desarrollo desde 
                      el nacimiento hasta la adolescencia.
                      Dentro del área clínica, tiene como objetivos conocer el estado de normalidad del niño o adolescente, así como identificar las desviaciones
                      de la normalidad mediante la aplicación de métodos diagnósticos (entrevista clínica y exploración física). Valora el estado inicial y, de 
                      acuerdo con su evolución, el pronóstico de cada periodo clínico.
                      El pediatra ofrece el mejor tratamiento de acuerdo a la enfermedad y condición del niño (edad, desarrollo, antecedentes) y contribuye a la 
                      reinserción en la normailidad o, en su caso, planificar y efectuar una adecuada rehabilitación.
                      En la Clínica Neurocom contamos con un excelente servicio de Pediatría brindando la calidez y confianza que ud y sus hijos requieren.
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    
                    <Button
                      onClick={() => setClassicModal5(false)}
                      color="danger"
                      simple
                    >
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
                
              </GridItem>


              <GridItem xs={6} sm={3}>
                <Button
                  color="primary"
                  block
                  onClick={() => setClassicModal6(true)}
                >
                  <GroupWork className={classes.icon} />
                  Neurofisiología
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal,
                  }}
                  open={classicModal6}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <IconButton
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={() => setClassicModal6(false)}
                    >
                      <Close className={classes.modalClose} />
                    </IconButton>
                    <h4 className={classes.modalTitle}>Neurofisiología</h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>La Neurofisiología es una disciplina médica cuyo objetivo básico es el estudio fisiopatológico con la ayuda de 
                      instrumentos de gabinete como EEG y Mapeo.
                      El servicio de Neurofisiología con el que contamos en la Clinica Especializada Neurocom va relacionado con la 
                      realización de electroencefalograma digital y con Mapeo cerebral.
                      Es una herramienta complementaria para el adecuado diagnóstico, por lo que tenemos a un equipo de especialistas 
                      que podrá guiar al paciente para un tratamiento adecuado.
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    
                    <Button
                      onClick={() => setClassicModal6(false)}
                      color="danger"
                      simple
                    >
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
                
              </GridItem>

              <GridItem xs={6} sm={3}>
                <Button
                  color="primary"
                  block
                  onClick={() => setClassicModal7(true)}
                >
                  <School className={classes.icon} />
                  Terapia de lenguaje
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal,
                  }}
                  open={classicModal7}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <IconButton
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={() => setClassicModal7(false)}
                    >
                      <Close className={classes.modalClose} />
                    </IconButton>
                    <h4 className={classes.modalTitle}>Terapia de lenguaje</h4>
                    
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>En la terapia de lenguaje se trabaja con niños que presentan dificultades en el habla, problemas con la producción de sonidos, o 
                      fallas al combinar palabras para transmitir ideas.</p>
                      <p>Su objetivo principal es establecer la comunicación no desarrollada o alterada a través de ejercicios de acuerdo a su edad de modo 
                      que la persona pueda sentirse más seguro para interactuar, expresar lo que piensa, quiere y necesita.
                      
                    </p>
                  </DialogContent>
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    
                    <h4 className={classes.modalTitle}>Terapia de Aprendizaje</h4>
                    
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                      Consiste en adquirir o modificar habilidades, destrezas, conocimientos o conductas, así como obtener o mejorar las estrategias de estudio, 
                      el razonamiento y la observación.</p>
                      <p>Este tipo de terapias va dirigida a niños y adolescentes. El paciente adquiere las herramientas necesarias para construir su conocimiento 
                      ejercitando la capacidad de concentración, atención, memoria y razonamiento enfatizando día a día la motivación, adquiriendo técnicas de 
                      estudio, así como aprender a organizar sus tiempos y planificar sus tareas.
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    
                    <Button
                      onClick={() => setClassicModal7(false)}
                      color="danger"
                      simple
                    >
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
                
              </GridItem>





            </GridContainer>
          </GridItem>
          
        </GridContainer>
        <div className={classes.title}>
          
        </div>
      </div>
    </div>
  );
}
