/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";

import Button from "components/CustomButtons/Button.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/downloadStyle.js";

const useStyles = makeStyles(styles);

export default function SectionDownload() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        
        <div className={classes.textCenter + " " + classes.sharingArea}>
          <GridContainer justify="center">
            <h3>Nuestros medios de contacto</h3>
          </GridContainer>
          <Button 
          color="twitter"
          href="https://twitter.com/DrNeurocom">
            <i className={classes.socials + " fab fa-twitter"} /> Twitter
          </Button>
          <Button 
          color="facebook"
          href="https://www.facebook.com/ClinicaNeurocom">
            <i className={classes.socials + " fab fa-facebook-square"} /> Facebook
          </Button>
          <Button 
          color="rose"
          href="https://www.instagram.com/drneurocom">
            <i className={classes.socials + " fab fa-instagram"} /> Instagram
          </Button>
          <Button 
          color="danger"
          href="https://www.youtube.com/channel/UCL1QhOUgwceAnjWTuYUPHyA">
            <i className={classes.socials + " fab fa-youtube"} /> Youtube
          </Button>

          <h3>Ubicación</h3>
          <p>Aniceto Ortega #633, col. Del Valle. Alcaldía Benito Juárez, CDMX. CP 03100</p>
          <p>correo electrónico: neurocom@neurocom.com.mx</p>
          <p>55-5523-1299</p>
          <p>55-5536-5637</p>
          <p>55-5536-5667</p>
          
        </div>
      </div>
    </div>
  );
}
