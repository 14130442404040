import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import LocalHospital from "@material-ui/icons/LocalHospital";
import Ballot from "@material-ui/icons/Ballot";
import LocalPharmacy from "@material-ui/icons/LocalPharmacy";
import Mail from "@material-ui/icons/Mail";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionPills() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          <div className={classes.title}>
            <h2>Protocolo</h2>
          </div>
          <div className={classes.title}>
            <h3>
              <small>Manejo de pacientes de primera vez </small>
            </h3>
          </div>
          <GridContainer >
            <GridItem xs={12} sm={12} md={12}  >
              <NavPills 
                color="primary"
                tabs={[
                  {
                    tabButton: "Exploración",
                    tabIcon: LocalHospital,
                    tabContent: (
                      <span>
                        <h3>
                        El paciente asiste a consulta con un Psiquiatra / Paidopsiquiatra. Se realiza la exploración del 
                        caso e historia clínca. A partir de ello, se le refiere al servicio que más se adecue a su necesidad 
                        (Laboratorios, EEG, Mapeo, Tac, Psicodiagnóstico o directamente a terapia).</h3> 
                        <h3>Este protocolo permite un adecuado diagnóstico, tratamiento y evolución del paciente. </h3>
                        <br />
                        
                      </span>
                    ),
                  },
                  {
                    tabButton: "Psicodiagnóstico",
                    tabIcon: Ballot,
                    tabContent: (
                      <span>
                        <h3>
                          Si se requiere más información del paciente, se refiere a una valoración psicológica donde se aplican baterías 
                          psicológicas y así, tener más información sobre las áreas en las que el paciente requiere apoyo.
                        </h3>
                        <br />
                      </span>
                    ),
                  },
                  {
                    tabButton: "Neurofisiología",
                    tabIcon: LocalPharmacy,
                    tabContent: (
                      <span>
                        <h3>
                          Como parte de la evaluación, puede ser necesario un mapeo cerebral con electroencefalograma digital 
                          para conocer el funcionamiento orgánico del cerebro.
                        </h3>
                        <br />
                      </span>
                    ),
                  },
                  {
                    tabButton: "Resultados de estudios",
                    tabIcon: Mail,
                    tabContent: (
                      <span>
                        <h3>
                          A partir de los resultados de las evaluaciones en psicodiagnóstico y/o mapeo cerebral, el psiquiatra recomienda la(s) terapia(s) adecuadas
                          para el paciente. Así como tratamiento farmacológico en caso de ser requerido.
                        </h3>
                        <br />
                      </span>
                    ),
                  },
                  {
                    tabButton: "Tratamiento",
                    tabIcon: Check,
                    tabContent: (
                      <span>
                        <h3>
                          Se inicia el tratamiento en la terapia convenida. Asímismo, se mantiene la supervisión médica constante.
                        </h3>
                        <br />
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
